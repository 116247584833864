@charset "utf-8";

// Common （統合）
//dart-sass使用にともない、mixin記述等に注意が必要
@use "config" as *;

html {
	font-family: $default;
	/*font-family: "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic ProN","Hiragino Kaku Gothic Pro","游ゴシック","游ゴシック体","Yu Gothic",YuGothic,verdana,"メイリオ","Meiryo","Osaka",sans-serif;*/
	/*font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "游明朝", YuMincho, "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;*/
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	font-size:62.5%;
	color: $bc00;
	
	//typekit読み込み対応
	opacity: 0;
	transition: opacity 1s;
	//transition-delay:1s;
	
	//webフォント文字太り対策
	-webkit-font-smoothing: antialiased;//仮設置
 -moz-osx-font-smoothing: grayscale;//仮設置
}
html.wf-active, html.op-active {
	opacity:1;
}
/*html.safari {
	-webkit-font-smoothing: antialiased;
}*/
@media (min-width:421px) {
	html {
		font-feature-settings : "palt";
	}
	/*html.safari {
		font-feature-settings : "pkna";
	}*/
}

@media (max-width:420px) {
	html {
		font-size:3.2vw;
		//font-feature-settings : "palt";//暫定　全てに効かせるとinlineblockで難アリ
	}
}
@media (min-width:421px) {
	body {
		min-width: 1000px;
	}
}

.touchevents * {
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}
.chrome body {
	image-rendering: -webkit-optimize-contrast;
}
body,h1,h2,h3,h4,h5,h6,p,dl,dt,dd,ol,ul,li,td,th,figure,input,select,button,textarea {
	margin:0;
	padding:0;
}
main {
	overflow: hidden;
}
article,aside,footer,header,main,nav,section,figcaption,figure {
	display:block;
}
img {
	-ms-interpolation-mode:bicubic;
	border-style:none;
}
svg:not(:root) {
	overflow:hidden;
}
hr{
	height:0;
	overflow:visible;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
b,strong {
	font-weight: inherit;
	font-weight: bolder;
}
a {
	outline:0;
	text-decoration:none;
	color: inherit;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}
li {
	list-style:none;
}
sub,sup {
	position:relative;
	vertical-align:baseline;
	line-height: 0;
}
sub {
	bottom:-.25em;
}
sup {
	top:-.5em;
}
audio,video {
	display: inline-block;
}
summary {
	display: list-item;
}
audio:not([controls]) {
	display: none;
	height: 0;
}
canvas {
	display: inline-block;
}
input,select,button,textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
	background: none;
	border:none;
	outline: 0;
	font:inherit;
}
button,input {
	overflow: visible;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
	border:0;
	padding:0;
}
button:-moz-focusring,
input:-moz-focusring {
	outline:1px dotted ButtonText;
}
button,select {
	cursor:pointer;
	text-transform:none;
}
[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner,button::-moz-focus-inner {
	border:none;
}
select::-ms-expand {
	display: none;
}


// data-sc （メディアクエリごとの表示設定）
[data-sc-pc] {
	@include sc(sp) {
		display:none!important;
	}
}
[data-sc-sp] {
	@include sc(pc) {
		display:none!important;
	}
}

.mod_main {
	overflow:hidden;
}

.ph {
	background-position:center;
	background-size:cover;
	background-repeat:no-repeat;
}

//mod_mainのipadの1pxズレ対策
.device-tablet .mod_main {
	width:calc(100% + 1px);
}

.no-touchevents [data-lf] {
	filter:opacity(0);
	transition:filter 1s;
	transform: scale(1);//iPad用
	&[style],&[src] {
		filter:opacity(1);
	}
	&.load_view {
		filter:opacity(1);
	}
}
.touchevents [data-lf],.ie [data-lf] {
	opacity:0;
	transition:opacity 1s;
	transform: scale(1);
	&[style] {
		opacity:1;
	}
	&.load_view {
		opacity:1;
	}
}

//data-lf読み込み時の非表示対策
img {
	opacity:0;
	transition: opacity .5s;//任意調整
	&[src] {
		opacity:1;
	}
}

body {
	line-height: 1.8;
	min-width: 1024px;//1100px;
	padding-top: 100px;
	
	@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
		padding-top: 75px;
		//max-width: 768px;
		//min-width: 768px;
	}
	@include sc(sp){
		padding-top: 50px;
		line-height: 1.6;
		min-width: 0;
	}
}


//iPad inline行末1文字落ち調整（暫定）効いていなかったのでコメントアウト
/*@include sc(pc){
	.os-iOS & {
		* {
			font-feature-settings : normal!important;
			letter-spacing: 0!important;
		}
	} 
}*/


/* ---------------------------------------------------------

_variableをクラス化

--------------------------------------------------------- */

/*
margin:0;版をデフォルト化

html例）
<ul class="col_3 col_base">
	<li></li>
	<li></li>
	<li></li>
</ul>
*/
.col_base {
	@include colmg(0);
}//.col_base

.mod_wrap {
	@extend %mod_w01;
}//.mod_wrap

.fontbold {
	@extend %fontbold;
}//.fontbold

.clearfix {
	@extend %clearfix;
}//.clearfix

.vertmiddle {
	@extend %vertmiddle;
}//.vertmiddle

.rightarr {
	@extend %rightarr;
}//.rightarr

.ph {
	@extend %ph;
}//.ph




/* -----------------------------------------------------------------------------

共通パーツ

----------------------------------------------------------------------------- */
	/*@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
	}*/

//リンクホバー時ライン
.underline {
	.no-touchevents &[href]:hover > span:before,
	.touchevents &[href].hover > span:before{
		transform: scaleX(1);
		transform-origin: left top;
	}
	> span {
		position: relative;
		display: inline-block;
		&:before {
			content: "";
			display: block;
			position: absolute;
			bottom: -2px;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: $bc05;

			transform: scaleX(0);
			transform-origin: right top;
			transition: transform 0.4s cubic-bezier(0.770, 0.000, 0.175, 1.000);
		}
	}
	
	&.current {
		> span {
			&:before {
				transform: scaleX(1);
				transition: none;
			}
		}
	}
}


//リンクボタン
.mod_btn01 {
	text-align:center;
	display:inline-block;
	max-width: 380px;
	width: 100%;
	height: 50px;
	text-align: center;
	padding: 0 20px;
	box-sizing: border-box;
	position: relative;
	background-color: $bcg00;
	transition: box-shadow 0.3s;//,background 0.3s
	box-shadow: 0 0 3px rgba($bc01,0);
	
	@include sc(sp) {
		display:block;
		height: 4.4rem;
		max-width: 100%;
		padding: 0 2.0rem;
	}
	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $bcy00;

		transform: scaleX(0);
		transform-origin: right top;
		transition: transform 0.4s cubic-bezier(0.770, 0.000, 0.175, 1.000);
	}
	
	> span {
		display: inline-block;
		@include f_ryo(500);
		@include fz(14);
		letter-spacing: 0.12em;
		line-height: 1.6;
		@include vaM;
		top:52%;//vaM調整値
		color: $bcff;
		transition: color 0.3s;
	
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			@include fz(11);
		}
		@include sc(sp){
			@include fz(12);
			
		}
	}
	> i {}
	.no-touchevents &:hover,
	.touchevents &.hover {
		box-shadow: 3px 3px 5px rgba($bc01,0.2);
		&:before {
			transform: scaleX(1);
			transform-origin: left top;
		}
		span {
			color: $bc00;
		}
		svg {
			fill: $bc00;
		}
	}
	
	&.external {
		> span {
			padding-right: 2.0rem;
		}
		i {
			position: absolute;
			right: 0rem;
			top: 50%;
			margin-top: -1.0rem;
			
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				margin-top: -0.8rem;
			}
			@include sc(sp){
				right: 0rem;
				top: 50%;
				margin-top: -0.8rem;
			}
			&:before {
				content: none;
			}
		}
		svg {
			width: 1.5rem;
			height: 1.3rem;
			fill: #fff;
			
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				width: 1.1rem;
				height: 0.9rem;
			}
			@include sc(sp){
				width: 1.1rem;
				height: 1.0rem;//0.9rem;
			}
		}
	}
	&.tpcl {
		background-color: rgba($bcy00,0);
		border: 1px solid $bcff;
		.no-touchevents &:hover,
		.touchevents &.hover {
			border-color: $bcy00;
			//background-color: rgba($bcy00,1.0);
		}
	}
	&.tpor {
		background-color: #e95732;
	}
}//.mod_btn01
.mod_link {
	> span {
		@include f_ryo(400);
		@include fz(12);
		letter-spacing: 0.11em;
		
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			@include fz(11);
		}
		@include sc(sp){
			@include fz(10);
			letter-spacing: 0.1em;
		}
	}
}




//タイトル
.mod_tit01 {
	@include f_ryo(500);
	@include fz(14);
	letter-spacing: 0.12em;
	line-height: 1;
	color: $bc00;
	text-align: center;
	
	@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
		@include fz(11);
	}
	@include sc(sp){
		@include fz(11);
		margin: 0 auto;
	}
	span {
		display: inline-block;
		@include f_yantra;
		@include fz(34);
		font-weight: 900;
		letter-spacing: 0.05em;//0.04em;
		line-height: 0.8;//1.1;
		margin-bottom: 15px;
		
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			@include fz(26);
			margin-bottom: 11px;
		}
		@include sc(sp){
			@include fz(22);
			margin-bottom: 0.7rem;
		}
	}
}
.mod_tit02 {// 手書き文字
	@include f_caveat(400);
	@include fz(100);
	letter-spacing: 0.02em;
	line-height: 0.8;
	text-align: center;
	color: $bc00;
	
	@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
		@include fz(75);
	}
	@include sc(sp){
		@include fz(53);
	}
	> span {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			left: 50%;
			top: 50%;
			display: block;
			width: 400px;
			height: 70px;
			margin-left: -200px;
			margin-top: -20px;
			background-color: $bcff;
			
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				width: 300px;
				height: 53px;
				margin-left: -150px;
				margin-top: -15px;
			}
			@include sc(sp){
				width: 18.5rem;
				height: 3.96rem;
				margin-left: -9.25rem;
				
			}
		}
	}
}
.mod_tit03 {//下層メイン
	@include f_ryo(500);
	@include fz(14);
	letter-spacing: 0.12em;
	line-height: 1;
	text-align: center;
	position: relative;
	z-index: 1;
	
	@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
		@include fz(11);
	}
	@include sc(sp){
		@include fz(11);
	}
	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 50%;
		left: 50%;
		display: block;
		margin-top: -80px;
		margin-left: -190px;
		width: 380px;
		height: 160px;
		background-color: $bcff;
		
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			width: 286px;
			height: 120px;
			margin-top: -60px;
			margin-left: -143px;
		}
		@include sc(sp){
			width: 21.6rem;
			height: 6.16rem;
			margin-top: -3.14rem;//-3.08rem;
			margin-left: -10.8rem;
		}
	}
	span {
		display: inline-block;
		margin-bottom: 15px;
		@include f_yantra(900);
		@include fz(52);
		letter-spacing: 0.04em;
		line-height: 0.8;
		
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			@include fz(39);
		}
		@include sc(sp){
			@include fz(26);
			margin-bottom: 0.8rem;
		}
	}
	&.tp02 {
		&:before {
			content: none;
		}
	}
}







/* -----------------------------------------------------------------------------

共通レイアウト

----------------------------------------------------------------------------- */
//mod_header
body.nav_open {
	@include sc(sp) {
		-webkit-overflow-scrolling: auto;
		overflow-y:hidden;

		.nav_wrap {
			-webkit-overflow-scrolling: touch;
		}
	}
}//body.nav_open

.mod_header {
	text-align: left;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	transform: translate3d(0, 0, 0);
	box-sizing: border-box;
	border-bottom: 1px solid $bc09;
	background-color: $bcff;
	
	@include sc(pc){
		padding: 0 10px;
		min-width: 980px;
		height: 100px;//0;
	}
	@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
		min-width: 820px;//768px;
		height: 75px;
	}
	@include sc(sp){
		height: 50px;
	}
	> .in {
		max-width: 1000px;
		min-width: 980px;
		margin: 0 auto;
		padding: 0 10px;
		box-sizing: border-box;
		
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			max-width: 820px;//780px;
			min-width: 0;
		}
		@include sc(sp){
			padding: 0 1rem;
		}
	}
	.headerMenuFix & {
		position: fixed;
		top: 0!important;
	}
	
	//navパート
	.row01 {
		position: relative;
		z-index: 10;
		height: 50px;
		letter-spacing: 0;
		font-size: 0;
		
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			height: 38px;
		}
		.header_logo {
			display: inline-block;
			vertical-align: top;
			padding-top: 20px;
			margin-right: 0;
			
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				padding-top: 15px;
				// margin-right: 45px;
				margin-right: 0;
			}
			@include sc(sp){
				padding-top: 17px;
				margin-right: 0;
				img {
					width: 100%;
					max-width: 40vw;
					height: auto;
				}
			}
			a {
				display: inline-block;
				transition: opacity 0.3s;
				
				@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
					img {
						width: auto;
						height: 15px;
					}
				}
				.no-touchevents &:hover,
				.touchevents &.hover {
					opacity: 0.5;
				}
			}
		}
		//PC用
		.nav01 {
			display: inline-block;
			text-align: right;
			width: calc(100% - 516px);
			vertical-align: top;
			padding-top: 24px;
			
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				width: calc(100% - 387px);
				padding-top: 18px;
			}
			> li {
				display: inline-block;
				color: $bc05;
				&.current {
					a {
						color: $bcg00;
					}
				}
				a {
					@include f_ryo(500);
					@include fz(13);
					letter-spacing: 0.08em;
					line-height: 1;
					
					.os-iOS & {
						font-feature-settings : normal;
					}
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						@include fz(11);
					}
					&.tel {
						@include f_yantra(500);
						@include fz(17);
						letter-spacing: 0.04em;
						
						@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
							@include fz(13);
						}
					}
				}
				+ li {
					&:before {
						content: '／';
						display: inline-block;
						@include f_ryo(500);
						@include fz(13);
						letter-spacing: 0.08em;
						line-height: 1;
						margin: 0 0.25em;
						
						@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
							@include fz(11);
						}
					}
				}
			}
		}
		.nav02 {
			display: inline-block;
			vertical-align: top;
			max-width: 285px;
			letter-spacing: 0;
			font-size: 0;
			
			@include sc(pc){
				position: absolute;
				right: 0;
				top: 0;
			}
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				max-width: 214px;
			}
			@include sc(sp){
				display: inline-flex;
				margin-left: 2.83vw;
				margin-top: 5px;
			}
			
			@media screen and (max-width: 320px) {
				margin-left: 2.33vw;
			}
			> li {
				display: inline-block;
				vertical-align: top;
				text-align: center;
				
				@include sc(pc){
					width: 95px;
					border-left: 1px solid $bc09;
					box-sizing: border-box;
					&:last-child {
						border-right: 1px solid $bc09;
					}
				}
				@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
					width: 71px;
				}
				@include sc(sp){
					width: 7.48rem;
					max-width: 85px;
					height: 3.52rem;

					&:nth-of-type(1) {//お友達紹介
						order: 1;
						width: 5.2rem;
						margin-left: 1.8vw;
						> a {
							border: 1px solid $bcg00;
							background-color: #fff;
							color: $bcg00;
							box-sizing: border-box;
						}
					}
					&:nth-of-type(2) {//メンテナンス&リフォーム
						order: 0;
					}
				}


				a {
					display: block;
					@include f_ryo(500);
					@include fz(14);
					letter-spacing: 0.08em;
					line-height: 1.35;
					color: $bcg00;
					box-sizing: border-box;
					transition: color 0.3s, background 0.3s;
					
					@include sc(pc){
						height: 100px;
						padding-top: 20px;
					}
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						height: 75px;
						padding-top: 15px;
						@include fz(11);
					}
					@include sc(sp){
						background-color: $bcg00;
						color: $bcff;
						@include fz(10);
						line-height: 1.25;
						font-weight: 700;
						letter-spacing: 0;
						height: 40px;
						transition: opacity .5s;
					}
					span {
						@include sc(sp){
							display: inline-block;
							@include vaM;
						}
					}
					.no-touchevents &:hover,
					&.current {
						background-color: $bcg00;
						color: $bcff;
						svg {
							fill: $bcff;
						}
					}
					.touchevents &.hover{
						opacity: 0.5;
					}
				}
				i {
					display: block;
					line-height: 0;
					margin-bottom: 10px;
					
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						margin-bottom: 8px;
					}
					@include sc(sp){
						display: none;
					}
				}
				svg {
					position: relative;
					fill: $bcg00;
					transition: fill 0.3s;
				}
				.heart {
					width: 22px;
					height: 20px;
					
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						width: 17px;
						height: 15px;
					}
				}
				.smile {
					width: 22px;
					height: 20px;
					// fill: #fff!important;
					@include sc(pc) {
						border: 1px solid transparent;
						box-sizing: border-box;
						border-radius: 50%;
					}
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						width: 17px;
						height: 15px;
					}
				}
				.fukidashi {
					width: 24px;
					height: 21px;
					
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						width: 18px;
						height: 16px;
					}
				}
			}
		}
	}
	.row02 {
		letter-spacing: 0;
		font-size: 0;
		.nav03 {
			display: inline-block;
			vertical-align: top;
			margin-top: 17px;
			
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				margin-top: 13px;
			}
			> li {
				display: inline-block;
				+ li {
					margin-left: 17px;//40px
					
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						margin-left: 13px;//30px;
					}
				}
				a {
					@include f_ryo(500);
					@include fz(14);
					letter-spacing: 0.08em;
					line-height: 1;
					
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						@include fz(12);//11
					}
				}
				span {
				 &:before {
						z-index: -1;
						left: -5px;
						width: calc(100% + 10px);
						height: 10px;
						background-color: $bcy00;
						
						@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
							left: -4px;
							width: calc(100% + 8px);
							height: 8px;
						}
					}
				}
			}
		}
		.nav04 {
			display: inline-block;
			vertical-align: top;
			margin-left: 14px;//22px;
			margin-top: 11px;
			
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				margin-left: 12px;
				margin-top: 8px;
			}
			> li {
				display: inline-block;
				vertical-align: top;
				+ li {
					margin-left: 10px;//6px;
					
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						margin-left: 8px;
					}
				}
				a {
					display: block;
					width: 22px;
					height: 22px;
					text-align: center;
					
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						width: 17px;
						height: 17px;
					}
					.no-touchevents &:hover,
					.touchevents &.hover {
						svg {
							fill: $bcg00;
						}
					}
				}
				svg {
					position: relative;
					top: 50%;
					margin-top: -9px;
					width: 20px;
					fill: $bc05;
					transition: fill 0.3s;
					
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						width: 15px;
					}
				}
				.fb{
					width: 10px;
					height: 18px;
					
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						width: 8px;
						height: 14px;
					}
				}
				.insta {
					left: -2px;
					width: 18px;
					height: 18px;
					
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						width: 14px;
						height: 14px;
					}
				}
				.youtube {
					width: 22px;
					height: 16px;
					padding-top: 1px;
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						width: 14px;
						height: 10px;
					}
				}
				.contact {
					width: 20px;
					height: 18px;
					stroke: $bc05;
					stroke-width: 0.02rem;
					
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						width: 15px;
						height: 14px;
					}
				}
			}
		}
	}
	
	//SPメニュー
	.nav_wrap {
		position: absolute;
		top: 50px;
		left: 0;
		width: 100vw;
		height: calc(100vh - 50px);
		//overflow: hidden;
		pointer-events: none;
		.nav_open & {
			pointer-events: auto;
		}
		.ie10 &,
		.ie9 & {
			display: none;
		}
		.nav_open .ie9 &,
		.headerMenuOn .ie10 & {
			display: block;
		}
		> .in {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			transition: 0.6s;
			transform: translateX(50%);
			opacity: 0;
			background-color: $bcff;
			.nav_open & {
				transform: translateX(0);
				opacity: 1;
			}
			.nav01 {
				font-size: 0;
				letter-spacing: 0;
				text-align: left;
				position: relative;
				&:before {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: 50%;
					z-index: -1;
					display: block;
					width: 1px;
					background-color: $bc07;
				}
				> li {
					display: inline-block;
					vertical-align: top;
					width: 50%;
					border-bottom: 1px solid $bc07;
					box-sizing: border-box;
					text-align: center;
					> a {
						position: relative;
						display: block;
						height: 3.52rem;
						@include fz(12);
						font-weight: 600;
						letter-spacing: 0.01em;
						color: $bc00;
						transition: opacity 0.3s;
						.touchevents &.hover {
							opacity: 0.5;
						}
						&.small {
							//@include fz(11);
							@include fz(10);
							letter-spacing: 0em;
						}
						span {
							display: inline-block;
							@include vaM;
						}
						i {
							&:before {
								content: '';
								position: absolute;
								top: 50%;
								right: 0.7rem;
								margin-top: -0.25rem;
								display: block;
								width: 0.5rem;
								height: 0.5rem;
								border-top: 1px solid $bc00;
								border-right: 1px solid $bc00;
								transform: rotate(45deg);
							}
						}
						&.external {
							em {
								font-style: normal;
								@include fz(11);
							}
							i {
								position: absolute;
								right: .7rem;
								top: 50%;
								margin-top: -0.8rem;

								&:before {
									content: none;
								}
							}
							svg {
								width: 1.1rem;
								height: 0.9rem;
								fill: #000;
							}
						}
					}
					&.inLink {
						> a {
							i {
								&:before,&:after {
									content: '';
									position: absolute;
									right: 0.7rem;
									top: 50%;
									border: none;
									display: block;
									width: 0.6rem;
									height: 1px;
									background-color: $bc00;
									transform: rotate(0);
									transition: rotate 0.3s;
								}
								&:before {
									margin-top: -0.15rem;
								}
								&:after {
									margin-top: 0.15rem;
								}
							}
						}
						.inLinkWrap {
							display: block;
							width: 200%;
						}
						&.current {
							> a {
								background-color: #ccc;
								i {
									&:before,&:after{
										width: 0.8rem;
									}
									&:before {
										margin-top: 0;
										transform: rotate(45deg);
									}
									&:after {
										margin-top: 0;
										transform: rotate(-45deg);
									}
								}
							}
							/*.inLinkWrap {
								display: block;
								width: 200%;
							}*/
						}
						&:nth-child(even){
							.inLinkWrap {
								margin-left: -100%;
							}
						}
					}
					&.wide {
						width: 100%;
						background-color: $bcff;
					}
				}
				.second {
					background-color: $bc07;
					> li {
						display: block;
						margin-left: 2rem;
						height: 40px;
						text-align: left;
						+ li {
							border-top: 1px solid $bcff;
						}
						a {
							@include fz(11);
							font-weight: 400;//300;
							letter-spacing: 0.08em;
							line-height: 1;
							color: $bc05;
							display: block; 
							height: 40px;
							transition: opacity 0.3s;
							span {
								display: inline-block;
								@include vaM;
								&:before {
									content: '・';
									display: inline-block;
									vertical-align: middle;
								}
							}
							.touchevents &.hover {
								opacity: 0.5;
							}
						}
						p {//リンク不活性版用
							@include fz(11);
							font-weight: 400;//300;
							letter-spacing: 0.08em;
							line-height: 1;
							color: $bc05;
							display: block; 
							height: 40px;
							transition: opacity 0.3s;
							span {
								opacity: 0.2;
								display: inline-block;
								@include vaM;
								&:before {
									content: '・';
									display: inline-block;
									vertical-align: middle;
								}
							}
							/*.touchevents &.hover {
								opacity: 0.5;
							}*/
						}
					}
				}
			}
			.address {
				text-align: center;
				display: block;
				@include fz(11);
				letter-spacing: 0.08em;
				line-height: 1.75;
				color: $bc05;
				margin-top: 2.4rem;
				margin-bottom: 1rem;
			}
			.nav02 {
				font-size: 0;
				letter-spacing: 0;
				text-align: center;
				> li {
					display: inline-block;
					vertical-align: top;
					width: 44.44%;
					+ li {
						margin-left: 0.2rem;
					}
					> a {
						display: block;
						height: 3.52rem;
						padding: 0;
						background-color: $bcg00;
						color: $bcff;
						position: relative;
						text-align: center;
						span {
							@include fz(12);
							letter-spacing: 0.08em;
							font-weight: 700;
						}
						&.tel {
							span {
								@include f_yantra(700);
								@include fz(14);
								letter-spacing: 0.04em;
							}
						}
					}
					i {
						display: inline-block;
						vertical-align: middle;
						margin-right: 5px;
					}
					svg {
						position: relative;
						fill: $bcff;
						stroke: $bcff;
						stroke-width: 1;
						&.contact {
							width: 1.4rem;
							height: 1.2rem;
							top: 0.5rem;
						}
						&.tel {
							width: 1.6rem;
							height: 1.6rem;
							top: 0.3rem;
							stroke-width: 1;//0.5;
						}
					}
				}
			}
			.nav03 {
				font-size: 0;
				letter-spacing: 0;
				text-align: right;
				margin: 1.76rem 0;
				padding: 0 2rem;
				> li {
					display: inline-block;
					vertical-align: top;
					+ li {
						margin-left: 1.5rem;
					}
					a {
						display: block;
						width: 2.2rem;
						&.mod_link {
							width: auto;
							color: $bc05;
							@include fz(11);
							letter-spacing: 0;
							font-weight: 400;//300;
							text-decoration: underline;
						}
					}
					svg {
						fill: $bc05;
					}
					.fb {
						width: 1.2rem;
						height: 2.2rem;
					}
					.insta {
						width: 2.2rem;
						height: 2.2rem;
					}
					.youtube {
						width: 2.2rem;
						height: 2.2rem;
					}
				}
			}
			.bnr {
				padding: 0 2rem;
				margin-bottom: 4rem;
				a {
					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
}//.mod_header

.crumblist {
	display: block;
	height: 24px;//35px;
	max-width: 1000px;
	padding: 2px 10px;//5px 10px;
	margin: 0 auto;
	box-sizing: border-box;
	
	@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
		max-width: 820px;
		min-width: 0;
	}
	@include sc(sp){
		display: none;
	}
	.link {
		display: inline-block;
		vertical-align: top;
		+ .link {
			padding-left: 3.5em;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				left: 1em;
				top: 50%;
				margin-top: -4px;
				width: 7px;
				height: 7px;
				border-top: 1px solid $bc05;
				border-right: 1px solid $bc05;
				transform: rotate(45deg);
				box-sizing: border-box;
			}
		}
	}
	.linkin {
		@include fz(12);
		color: $bc05;
	}
}
.mod_spNav_btn {
	display:block;
	position:fixed;
	border:1px solid transparent;
	padding: 11px 9px;
	margin-top: 7px;
	top:0;
	right:0;
	z-index:1200;
	background-color:rgba($bc00,0);
	transition:background 1s,border 1s;
	span {
		position: relative;
		top: 8px;
		@include f_yantra(700);
		@include fz(9);
		letter-spacing: 0.04em;
		color: rgba($bc00,1);
		&:before {
			content: 'CLOSE';
			position: absolute;
			top: -2px;
			left: -0.15em;
			right: 0;
			display: block;
			text-align: center;
			color: rgba($bc00,0);
		}
	}
	i {
		display:block;
		margin:auto;
		width: 52%;
		height: 2px;
		position:absolute;
		background-color: $bc00;
		right:0;
		left:0;
		top:-18px;
		bottom:0;
		transition:background 0.3s;
		&:before,&:after {
			content:"";
			display:block;
			width:100%;
			height:100%;
			background-color: $bc00;
			transition:transform 0.5s, top 0.5s, bottom 0.5s, background, 0.5s;
			position:absolute;
			left:0;
		}
		&:before {
			top:-7px;
		}
		&:after {
			bottom:-7px;
		}
	}
	
	/*&.fix {
		background-color:$bcff;
	}//&.fix*/
	.nav_open & {
		border:1px solid transparent;
		span {
			color: rgba($bc00,0);
			&:before {
				color: rgba($bc00,1);
			}
		}
		i {
			background-color:rgba(255,255,255,0);
			&:before,&:after {
				top:0;
				bottom:0;
				background-color:$bc00;
			}
			&:before {
				transform:rotate(45deg);
			}
			&:after {
				transform:rotate(-45deg);
			}
		}
	}//&.open
}//.mod_spNav_btn


//mod_footer
.mod_footer {
	> .in {
		text-align: center;
		padding-top: 70px;
		padding-bottom: 60px;
		border-top: 4px solid $bcg00;
		box-sizing: border-box;
		
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			padding-top: 53px;
			padding-bottom: 45px;
		}
		@include sc(sp){
			border-width: 0.2rem;
			padding-top: 3rem;
			padding-bottom: 0;
		}
	}
	.row01{
		letter-spacing: 0;
		font-size: 0;
		margin: 0 auto;

		@include sc(pc){
			max-width: 980px;
			text-align: center;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
		}
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			max-width: 800px;//736px;
		}
		@include sc(sp){
			border-top: 1px solid $bc07;
		}
	}
	.col01,.col02,.col03 {
		@include sc(pc){
			width: 245px;
			padding: 0 20px;
			box-sizing: border-box;
			border-left: 1px solid $bc07;
			text-align: left;
		}
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			width: 184px;
			padding: 0 15px;
		}
	}
	.col03 {
		@include sc(pc){
			width: 240px;
		}
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			width: 180px;
		}
	}
	.row02 {
		background-color: $bcg00;
		> .in {
			padding: 20px 0;
			margin: 0 auto;
			box-sizing: border-box;
			text-align: center;
			letter-spacing: 0;
			font-size: 0;
			
			@include sc(pc){
				max-width: 980px;
				height: 120px;
			}
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				padding: 15px 0;
				max-width: 800px;//736px;
				height: 90px;
			}
			@include sc(sp){
				padding: 2.46rem 1.76rem;
			}
			.title {
				@include sc(sp){
					color: $bcff;
					@include f_yantra(900);
					@include fz(22);
					letter-spacing: 0.05em;
					line-height: 1;
					margin-bottom: 2.46rem;
				}
			}
			a {
				display: inline-block;
				line-height: 0;
				transition: opacity 0.5s;
				
				@include sc(pc){
					+ a {
						margin-left: 12px;
					}
				}
				@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
					img {
						width: auto;
						height: 64px;//60px;
					}
					+ a {
						margin-left: 12px;//9px;
					}
				}
				@include sc(sp){
					display: block;
					+ a {
						margin-top: 1rem;
					}
					img {
						width: 100%;
						height: auto;
					}
				}
				.no-touchevents &:hover,
				.touchevents &.hover {
					opacity: 0.7;
				}
			}
		}
	}
	.row03 {
		> .in {
			margin: 0 auto;
			box-sizing: border-box;
			position: relative;
			
			@include sc(pc){
				max-width: 980px;
				padding-top: 25px;
				height: 100px;
			}
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				max-width: 800px;//736px;
				padding-top: 19px;
				height: 75px;
			}
			@include sc(sp){
				padding: 0 2rem 2rem;
			}
		}
		dl {
			@include fz(13);
			letter-spacing: 0.08em;
			line-height: 1.84;
			color: $bc05;
			
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				@include fz(11);
			}
		}
		.link_set02 {
			padding: 2.5rem 0 1rem;
			
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				padding: 1.9rem 0 0.75rem;
			}
		}
		small {
			color: $bc05;
			letter-spacing: 0.08em;
			line-height: 1;

			@include sc(pc){
				position: absolute;
				right: 0;
				bottom: 35px;
				@include fz(10);
			}
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				bottom: 26px;
				@include fz(9);
			}
			@include sc(sp){
				@include fz(7);
			}
		}
	}
	
	//element
	.footer_logo {
		margin-bottom: 70px;
		a {
			display: inline-block;
			line-height: 1;
		}
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			margin-bottom: 53px;
			a {
				img {
					width: auto;
					height: 21px;
				}
			}
		}
		@include sc(sp){
			margin-bottom: 2.8rem;
			text-align: center;
			img {
				width: auto;
				height: 1.67rem;
			}
		}
	}
	.link_set {
		@include sc(pc){
			a {
				@include fz(14);
				letter-spacing: 0.08em;
				line-height: 1.78;
				font-weight: 600;
				position: relative;
				transition: color 0.3s;
				
				&.external {
					i {
						position: absolute;
						right: -2rem;
						top: 50%;
						margin-top: -1.2rem;
						&:before {
							content: none;
						}
					}
					svg {
						width: 1.4rem;
						height: 1.2rem;
						fill: #000;
					}
				}
				.no-touchevents &:hover,
				.touchevents &.hover {
					color: $bcg00;
				}
			}
			.inLinkWrap {
				display: block;
			}
			.second {
				a {
					@include fz(14);
					letter-spacing: 0.08em;
					line-height: 1.85;
					font-weight: 400;
					color: $bc05;
					&:before {
						content: '・';
						display: inline-block;
					}
				}
				p {//リンク不活性版
					opacity: 0.3;
					@include fz(14);
					letter-spacing: 0.08em;
					line-height: 1.85;
					font-weight: 400;
					color: $bc05;
					&:before {
						content: '・';
						display: inline-block;
					}
				}
			}
			&.sub {
				margin-top: 20px;
				>.subtit {
					margin-top: 6px;
					line-height: 2.4;
					small {
						display: block;
						font-weight: 400;
						color: #7f7f7f;
						font-size: 1.2rem;
						@media screen and (min-width: 421px) and (max-width: 1366px) {
							font-size: 1rem;
						}
					}
					a {
						display: block;
					}
				}
				a {
					font-weight: 400;
					color: $bc05;
				}
			}
		}
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			a {
				@include fz(11);
			}
			.second {
				a {
					@include fz(11);
				}
				p {//リンク不活性版
					@include fz(11);
				}
				&.sub {
					margin-top: 15px;
				}
			}
		}
		@include sc(sp){
			font-size: 0;
			letter-spacing: 0;
			text-align: left;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 50%;
				display: block;
				width: 1px;
				background-color: $bc07;
			}
			> li {
				display: inline-block;
				vertical-align: top;
				width: 50%;
				border-bottom: 1px solid $bc07;
				box-sizing: border-box;
				text-align: center;
				> a {
					position: relative;
					display: block;
					height: 3.52rem;
					@include fz(12);
					font-weight: 600;
					letter-spacing: 0.01em;
					color: $bc00;
					transition: opacity 0.3s;
					
					.touchevents &.hover {
						opacity: 0.5;
					}
					&.small {
						@include fz(11);
						letter-spacing: 0;
					}
					span {
						display: inline-block;
						@include vaM;
					}
					i {
						&:before {
							content: '';
							position: absolute;
							top: 50%;
							right: 0.7rem;
							margin-top: -0.25rem;
							display: block;
							width: 0.5rem;
							height: 0.5rem;
							border-top: 1px solid $bc00;
							border-right: 1px solid $bc00;
							transform: rotate(45deg);
						}
					}
					&.external {
						em {
							font-style: normal;
							@include fz(11);
						}
						i {
							position: absolute;
							right: .7rem;
							top: 50%;
							margin-top: -0.8rem;

							&:before {
								content: none;
							}
						}
						svg {
							width: 1.1rem;
							height: 0.9rem;
							fill: #000;
						}
					}
				}
				&.inLink {
					> a {
						i {
							&:before,&:after {
								content: '';
								position: absolute;
								right: 0.7rem;
								top: 50%;
								border: none;
								display: block;
								width: 0.6rem;
								height: 1px;
								background-color: $bc00;
								transform: rotate(0);
								transition: rotate 0.3s;
							}
							&:before {
								margin-top: -0.15rem;
							}
							&:after {
								margin-top: 0.15rem;
							}
						}
					}
					&.current {
						border-bottom: none;
						> a {
							background-color: #ccc;
							i {
								&:before,&:after{
									width: 0.8rem;
								}
								&:before {
									margin-top: 0;
									transform: rotate(45deg);
								}
								&:after {
									margin-top: 0;
									transform: rotate(-45deg);
								}
							}
						}
						/*.inLinkWrap {
							display: block;
							width: 200%;
						}*/
					}
					.inLinkWrap {
						display: block;
						width: 200%;
					}
					&:nth-child(even){
						.inLinkWrap {
							margin-left: -100%;
						}
					}
				}
			}
			.second {
				background-color: $bc07;
				> li {
					display: block;
					margin-left: 2rem;
					height: 40px;
					text-align: left;
					+ li {
						border-top: 1px solid $bcff;
					}
					a {
						display: block;
						height: 100%;
						@include fz(11);
						letter-spacing: 0.01em;
						line-height: 1;
						color: $bc05;
						transition: opacity 0.3s;

						.touchevents &.hover {
							opacity: 0.5;
						}
						span {
							display: inline-block;
							@include vaM;
							&:before {
								content: '・';
								display: inline-block;
								vertical-align: middle;
							}
						}
					}
					p {//リンク不活性版
						display: block;
						height: 100%;
						@include fz(11);
						letter-spacing: 0.01em;
						line-height: 1;
						color: $bc05;
						transition: opacity 0.3s;

						/*.touchevents &.hover {
							opacity: 0.5;
						}*/
						span {
							opacity: 0.2;
							display: inline-block;
							@include vaM;
							&:before {
								content: '・';
								display: inline-block;
								vertical-align: middle;
							}
						}
					}
				}
			}
		}
	}
	.link_set02 {
		text-align: left;
		margin: 0 -0.8em;
		> li {
			display: inline-block;
			@include fz(11);
			line-height: 1;
			color: $bc05;
			padding: 0 0.8em;
			box-sizing: 	border-box;
			
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				@include fz(10);
			}
			+ li {
				border-left: 1px solid $bc05;
			}
		}
		>.subtit {
			margin-top:10px;
			border-left:none;
			line-height: 2.2;
			small {
				margin-right:5px;
				font-size: .9rem;
			}
			a {
				display: block;
			}
		}
	}
	.split {
		@include sc(pc){
			margin-top: 30px;
		}
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			margin-top: 23px;
		}
	}
	.contact_box {
		@include sc(sp){
			.address {
				text-align: center;
				display: block;
				@include fz(11);
				letter-spacing: 0.08em;
				line-height: 1.8;
				color: $bc05;
				margin-top: 2.2rem;
				margin-bottom: 1.2rem;
			}
			.nav02 {
				font-size: 0;
				letter-spacing: 0;
				text-align: center;
				> li {
					display: inline-block;
					vertical-align: top;
					width: 44.44%;
					+ li {
						margin-left: 0.2rem;
					}
					> a {
						display: block;
						height: 3.52rem;
						padding: 0;
						background-color: $bcg00;
						color: $bcff;
						position: relative;
						text-align: center;
						span {
							@include fz(12);
							line-height: 1.2;
							letter-spacing: 0.08em;
							font-weight: 700;
						}
						&.tel {
							span {
								@include f_yantra(700);
								@include fz(14);
								letter-spacing: 0.04em;
								line-height: 1.6;
							}
						}
					}
					i {
						display: inline-block;
						//vertical-align: middle;
						margin-right: 5px;
						height: 100%;
					}
					svg {
						position: relative;
						fill: $bcff;
						stroke: $bcff;
						stroke-width: 1;
						&.contact {
							width: 1.4rem;
							height: 1.2rem;
							top: 1.3rem;//0.8rem;
						}
						&.tel {
							width: 1.6rem;
							height: 1.6rem;
							top: 1rem;//0.3rem;
							stroke-width: 1;
						}
					}
				}
			}
		}
	}
	.sns_link {
		@include sc(pc){
			margin-top: 30px;
			li {
				display: inline-block;
				width: 26px;
				margin-bottom: 10px;
				color: $bc05;
				a {
					display: block;
					transition: color 0.3;
					&.mod_link {
						span {
							@include f_go(300);
							@include fz(14);
							letter-spacing: 0.08em;
							line-height: 1.87;
						}
					}
					
					.no-touchevents &:hover,
					.touchevents &.hover {
						color: $bcg00;
						svg {
							fill: $bcg00;
						}
					}
				}
				&:nth-child(4){
					display: block;
					width: 100%;
				}
			}
			svg {
				fill: $bc05;
				width: 18px;
				height: 18px;
				position: relative;
				transition: fill 0.3s;
			}
			
		}
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			margin-top: 23px;
			li {
				width: 20px;
				margin-bottom: 8px;
				a {
					&.mod_link {
						span {
							@include fz(11);
						}
					}
				}
			}
			svg {
				width: 14px;
				height: 14px;
			}
		}
		@include sc(sp){
			font-size: 0;
			letter-spacing: 0;
			text-align: right;
			margin: 1.76rem 0;
			padding: 0 2rem;
			> li {
				display: inline-block;
				vertical-align: top;
				+ li {
					margin-left: 1.5rem;
				}
				a {
					display: block;
					width: 2.2rem;
					&.mod_link {
						width: auto;
						color: $bc05;
						@include fz(11);
						font-weight: 400;
						text-decoration: underline;
					}
				}
				svg {
					fill: $bc05;
				}
				.fb {
					width: 1.2rem;
					height: 2.2rem;
				}
				.insta {
					width: 2.2rem;
					height: 2.2rem;
				}
				.youtube {
					width: 2.2rem;
					height: 2.2rem;
				}
			}
		}
	}
	.fb_box {
		position: relative;
		width: 250px;
		height: 414px;//400px;
		background-color: #fff;
		
		@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
			//width: 188px;
			height: 311px!important;
		}
		.fb_iframe_widget,
		.fb_iframe_widget span,
		.fb_iframe_widget iframe[style]{
			width: 100% !important;

			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				height: 311px !important;
			}
		}
		
		@include sc(sp){
			width: auto;
			height: 408px;
			margin-bottom: 30px;
			margin-left: 20px;
			margin-right: 20px;
			.fb-page {
				width: 100%;
			}
		}
	}
	.mod_toTop_area {
		position: relative;
		width: 100%;
		
		@include sc(pc){
			margin: 0 auto;
		}
		.mod_toTop {
			position: fixed;//absolute;
			transition: opacity 0.7s, background 0.3s;
			display: block;
			box-sizing: border-box;
			opacity: 0;
			
			@include sc(pc){
				background-color: $bc05;
				width: 60px;
				height: 60px;
				right: 20px;
				border-radius: 0.3rem;
				.footerPagetopView & {
					position: fixed;
					z-index: 1;
					opacity: 1;
					bottom: 20px;
				}
				.footerPagetopView_01 & {
					position: absolute;
				 bottom: 20px;
					opacity: 1;
				}
			}
			@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
				width: 45px;
				height: 45px;
				right: 15px;
				.footerPagetopView & {
					bottom: 15px;
				}
				.footerPagetopView_01 & {
					bottom: 15px;
				}
			}
			@include sc(sp){
				background-color: $bc05;
				width: 3.52rem;
				height: 3.52rem;
				right: 0.5rem;
				border-radius: 0.3rem;
				.footerPagetopView & {
					position: fixed;
					z-index: 1;
					opacity: 1;
					bottom: 0.5rem;
				}
				.footerPagetopView_01 & {
					position: absolute;
				 bottom: 0.5rem;
					opacity: 1;
				}
			}
			span {
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				transform: rotate(-45deg);
				transition: margin 0.3s;
				
				@include sc(pc){
					margin-top: -5px;
					margin-left: -10px;
					width: 20px;
					height: 20px;
					border-top: 1px solid $bcff;
					border-right: 1px solid $bcff;
				}
				@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
					margin-top: -4px;
					margin-left: -8px;
					width: 15px;
					height: 15px;
				}
				@include sc(sp){
					margin-top: -0.25rem;
					margin-left: -0.4rem;
					width: 0.8rem;
					height: 0.8rem;
					border-top: 0.2rem solid $bcff;
					border-right: 0.2rem solid $bcff;
					transform: rotate(-45deg);
				}
			}
			.no-touchevents &:hover,
			.touchevents &.hover {
				background-color: $bcg00;
				span {
					margin-top: -10px;
					
					@media screen and (min-width: $breakpointWidth00) and (max-width: $breakpointWidth02) {//1366
						margin-top: -8px;
					}
				}
			}
		}
	}
	
	//SP下部追随
	.flowNav {
		display: table;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 42px;
		z-index: 999;
		box-sizing: border-box;
		transition: 1.0s;
		transform: translateY(100%);
		
		.footerNavView & {
			transform: translateY(0%);
		}
		> a {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			width: 50%;
			height: 42px;
			box-sizing: border-box;
			span {
				@include f_ryo(500);
				@include fz(13);
				color: $bcff;
				display: inline-block;
			}
			&.external {
				> span {
					&:after {
						content: '';
						display: inline-block;
						width: 13px;
						height: 11px;
						margin-top: -5px;
						margin-left: 5px;
						background-image: url(../img/ico_external.svg);
						background-size: 100%	auto;
						background-repeat: no-repeat;
					}
				}
			}
		}
	}
}//.mod_footer


//movieモーダル 3d unfold
.mfp-3d-unfold {
	/* start state */
	/* animate in */
	/* animate out */
	&.mfp-wrap {
		perspective: 2000px;
	}
	.mfp-container {
		opacity: 0;
		transition: all 0.3s ease-in-out;
		transform-style: preserve-3d;
		transform: rotateY(-60deg);
	}
	&.mfp-bg {
		opacity: 0;
		transition: all 0.5s;
	}
	&.mfp-ready {
		.mfp-container {
			opacity: 1;
			transform: rotateY(0deg);
			padding-top: 50px;
		}
		&.mfp-bg {
			opacity: 0.8;
		}
	}
	&.mfp-removing {
		.mfp-container {
			transform: rotateY(60deg);
			opacity: 0;
		}
		&.mfp-bg {
			opacity: 0;
		}
	}
}







/* -----------------------------------------------------------------------------

モーション

----------------------------------------------------------------------------- */
[data-pararax01] {
	position: relative;
	
	[data-pararax01-inview] {
		position: absolute;
		top: 200px;
		bottom: 200px;
		left: 0;
		width: 100%;
		pointer-events: none;
		opacity: 0;
		
		@include sc(sp) {
			top: 100px;
			bottom: 100px;
		}
	}

	//シンプルフェードイン
	[data-pararax01-opacity01] {
		opacity: 0;
		//transition: opacity 2.0s cubic-bezier(0.625, 0.000, 0.355, 1.000);
		transition: opacity 1.0s cubic-bezier(0.625, 0.000, 0.355, 1.000);
		&.active {
			opacity: 1;
		}
	}
	
	//左よりin
	[data-pararax01-opacity02] {
		opacity: 0;
		//transition: opacity 2.0s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000);
		transition: opacity 1.0s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000);
		&.active {
			opacity: 1;
			transform: translate(0px, 0px);
		}
		& {
			transform: translate(-15px, -7px);//移動量
		}
	}
	
	//右よりin
	[data-pararax01-opacity03] {
		opacity: 0;
		//transition: opacity 2.0s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000);
		transition: opacity 1.0s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000);
		&.active {
			opacity: 1;
			transform: translate(0px, 0px);
		}
		& {
			transform: translate(15px, 7px);//移動量
		}
	}
	
	//下から上
	[data-pararax01-opacity04] {
		opacity: 0;
		//transition: opacity 1.7s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000);
		transition: opacity 1.0s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000);
		&.active {
			opacity: 1;
			transform: translate(0px, 0px);
		}
		& {
			transform: translate(0, 15px);//移動量
		}
	}
	
	//上から下
	[data-pararax01-opacity05] {
		opacity: 0;
		//transition: opacity 1.7s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000);
		transition: opacity 1.0s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000);
		&.active {
			opacity: 1;
			transform: translate(0px, 0px);
		}
		& {
			transform: translate(0, -15px);//移動量
		}
	}
	
	//小から大
	[data-pararax01-opacity06] {
		opacity: 0;
		//transition: opacity 2s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 1.3s cubic-bezier(0.625, 0.000, 0.355, 1.000);
		transition: opacity 1s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 1.3s cubic-bezier(0.625, 0.000, 0.355, 1.000);
		&.active {
			opacity: 1;
			transform: scale(1);
		}
		& {
			transform: scale(0.9)//移動量
		}
	}


	//右からin（line01/line02）
	[data-pararax01-copy01] {
		&.active {
			.line01 {
				transform: translateX(0);
				opacity: 1;
			}
		}
		.line01 {
			//transition: opacity 1s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000);
			transition: opacity 0.5s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000);
			transform: translateX(7px);
			opacity: 0;
		}
		.line02 {
			&.active {
				transform: translateX(0);
				opacity: 1;
			}
			& {
				transform: translateX(7px);
				//transition: opacity 1s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.2s, transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.2s;
				transition: opacity 0.5s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.2s, transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.2s;
				opacity: 0;
			}
		}
	}
	
	//左からin（line01/line02）
	[data-pararax01-copy02] {
		&.active {
			.line01 {
				transform: translateX(0);
				opacity: 1;
			}
		}
		.line01 {
			//transition: opacity 1s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000);
			transition: opacity 0.5s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000);
			transform: translateX(-7px);
			opacity: 0;
		}
		.line02 {
			&.active {
				transform: translateX(0);
				opacity: 1;
			}
			& {
				transform: translateX(-7px);
				//transition: opacity 1s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.2s, transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.2s;
				transition: opacity 0.5s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.2s, transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.2s;
				opacity: 0;
			}
		}
	}
	
	//左からin（title/line01/line02）
	[data-pararax01-title01] {
		&.active {
			.title {
				transform: translateX(0);
				opacity: 1;
			}
		}
		.title {
			//transition: opacity 1.7s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000);
			transition: opacity 1.0s cubic-bezier(0.625, 0.000, 0.355, 1.000), transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000);
			transform: translateX(7px);
			opacity: 0;
		}
		.line01 {
			&.active {
				transform: translateX(0);
				opacity: 1;
			}
			& {
				transform: translateX(7px);
				//transition: opacity 1.7s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.2s, transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.2s;
				transition: opacity 1.0s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.2s, transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.2s;
				opacity: 0;
			}
		}
		.line02 {
			&.active {
				transform: translateX(0);
				opacity: 1;
			}
			& {
				transform: translateX(7px);
				//transition: opacity 1.7s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.4s, transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.4s;
				transition: opacity 1.0s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.4s, transform 0.7s cubic-bezier(0.625, 0.000, 0.355, 1.000) 0.4s;
				opacity: 0;
			}
		}
	}


	[data-pararax01-clippath01] {
		transition: opacity 1s, clip-path 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000)!important;
		clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
		overflow: hidden;
		position: relative;
		.ie & {
			opacity: 0; 
		}
		&.active {
			clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
			.ie & {
				opacity: 1;
			}
			.clippath01 {
			}
		}
		.clippath01 {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			.clippath01in {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transform: scale(1.1);
				clip-path: polygon(100% 0%, 180% 0%, 180% 100%, 100% 100%, 100% 0%);
				transition: clip-path 3.0s cubic-bezier(0.000, 0.435, 0.000, 1.000) 0.0s;
			}
		}
	}


	//TOP/MVロゴ周り（不採用）
	[data-pararax01-clippath02] {
		.line01 {
			transition: opacity 0.2s, transform 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000), clip-path 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000)!important;
			clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
			position: relative;
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: $bcg00;
				clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
				transition: opacity 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000) 1.0s, clip-path 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000) 0.5s;//1.0s;
				.ie & {
					opacity: 1;
				}
			}
			&.active {
				clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
				transform: none;
				.ie & {
					opacity: 1;
				}
				&:after {
					clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
					.ie & {
						opacity: 0;
					}
				}
			}
		}
		.line02 {
			transition: opacity 0.2s, transform 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000), clip-path 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000)!important;
			clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
			position: relative;
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: $bcg00;
				clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
				transition: opacity 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000) 1.0s, clip-path 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000) 0.5s;//1.0s;
				.ie & {
					opacity: 1;
				}
			}
			&.active {
				clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
				transform: none;
				.ie & {
					opacity: 1;
				}
				&:after {
					clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
					.ie & {
						opacity: 0;
					}
				}
			}
		}
	}

	//右から左
	[data-pararax01-clippath03] {
		transition: opacity 0.2s, transform 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000), clip-path 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000)!important;
		clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
		//transform: translateY(40px);
		position: relative;
		.ie & {
			opacity: 0;
		}
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $bcg00;
			clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
			transition: opacity 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000) 1.0s, clip-path 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000) 0.5s;//1.0s;
			.ie & {
				opacity: 1;
			}
		}
		&.active {
			clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
			transform: none;
			.ie & {
				opacity: 1;
			}
			&:after {
				clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
				.ie & {
					opacity: 0;
				}
			}
		}
	}

	//左から右
	[data-pararax01-clippath04] {
		transition: opacity 0.2s, transform 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000), clip-path 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000)!important;
		clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
		//transform: translateY(40px);
		position: relative;
		.ie & {
			opacity: 0;
		}
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $bcg00;
			clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
			transition: opacity 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000) 1.0s, clip-path 0.8s cubic-bezier(0.000, 0.435, 0.000, 1.000) 0.5s;//1.0s;
			.ie & {
				opacity: 1;
			}
		}
		&.active {
			clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
			transform: none;
			.ie & {
				opacity: 1;
			}
			&:after {
				clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
				.ie & {
					opacity: 0;
				}
			}
		}
	}


}



