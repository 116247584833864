@charset "utf-8";

//デバイスフォント
$default: "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic ProN","Hiragino Kaku Gothic Pro","游ゴシック","游ゴシック体","Yu Gothic",YuGothic,verdana,"メイリオ","Meiryo","Osaka",sans-serif;

//変更不可
//$contentWidth: 1240px;//1000px;

$breakpointWidth00: 421px;//
$breakpointWidth01: 1439px;//vw-px切替境界値上限
$breakpointWidth02: 1366px;//1329px;　フォントサイズ切替値（75%）
$breakpointWidth03: 1239px;
$breakpointWidth04: 1159px;
$breakpointWidth05: 1024px;//vw-px切替境界値下限


// メディアクエリ
$sc_pc: 421px;
$sc_onlyPc: 1021px;
$sc_tab: 1020px;
$sc_sp: 420px;
$sc_spMin: 350px;

$sc_pcW: 1201px;
$sc_pcMin: 1400px;

@mixin sc($point){
	@if $point == pc{
		@media screen and (min-width:$sc_pc){
			@content;
		}
	}@else if $point == onlyPc{
		@media screen and (min-width:$sc_onlyPc){
			@content;
		}
	}@else if $point == pcMin{
		@media screen and (max-width:$sc_pcMin){
			@content;
		}
	}@else if $point == pcW{
		@media screen and (min-width:$sc_pcW){
			@content;
		}
	}@else if $point == tab{
		@media screen and (max-width:$sc_tab){
			@content;
		}
	}@else if $point == sp{
		@media screen and (max-width:$sc_sp){
			@content;
		}
	}@else if $point == spMin{
		@media screen and (max-width:$sc_spMin){
			@content;
		}
	}
}

//フォントサイズrem対応
@mixin fz($size){
	//font-size: $size + px;
	font-size: calc(($size / 10) * 1rem);
}

//ベースカラーセット
$bc00: #000;
$bc01: #212121;
$bc02: #262626;
$bc03: #313131;
$bc04: #4f4f4f;
$bc05: #7f7f7f;//リンク等グレー(icon)
$bc06: #f4f4f4;
$bc07: #e5e5e5;

$bc09: #efefef;//背景使用グレー
$bcff: #fff;


//緑系
$bcg00: #005b2b;//#002c00;//ロゴカラー

//黄系
$bcy00: #fff900;//メディアサイト･レモンイエロー


//ブラウザのsvgがデザインデータより少々細く見えるのでstrokeで調整、一括色指定用
@mixin svgfs($color){
	fill:$color;
	stroke:$color;
}


/*
カラム制御：デフォルトで1～5カラムまで対応
PC用floatによるカラム制御

html：col_1～5まで対応
例）3列で折り返し
<ul class="col_3">
	<li></li>
	<li></li>
	<li></li>
</ul>

scss：mixinでカラム設定を自動処理化

.col_3 {
	@include colmg(30);
}

数値設定はアイテム間余白（デフォルトは20）;
※縦、横ともに外側への約は発生しない

*/
@mixin colmg($mg:20) {
	@for $i from 1 through 5 {
		&.col_#{$i} {

			@include sc(pc) {
				@supports (display:flow-root) {
					display:flow-root;
				}
				@supports not (display:flow-root) {
					display:block;
					&:after {
						content: '';
						display: block;
						clear: both;
					}//&:after
				}
				.ie & {//ieは@supportsが効かない + floatの小数点px折り返し対策
					display:block;
					width:calc(100% + 1px);
					&:after {
						content: '';
						display: block;
						clear: both;
					}//&:after
				}//.ie &
				& > * {
					margin:#{$mg}px 0 0 #{$mg}px;
					width:calc(100% / #{$i} - calc(calc($mg * ($i - 1)) / $i)px);
					float:left;
					.ie & {//floatの小数点px折り返し対策 IEなのでコメントアウト
						width:calc((100% - 1px) / #{$i} - calc($mg * ($i - 1) / $i)px);
					}//.ie &
					&:nth-child(#{$i}n+1) {
						margin-left:0;
					}//&:nth-child(#{$i}n+1)
					&:nth-child(-n+#{$i}) {
						margin-top:0;
					}//&:nth-child(#{$i}n+1)
				}//& > *
			}
		}//.col_#{$i}
	}//@for $i from 1 through 10
}//@mixin colmg($mg:20)


// コンテンツ幅
$mod_w01: 1280px;
$mod_w01_min: 1000px;
$mod_w_pd01: 10px;
$mod_w_pd01_sp: 1.76rem;
$mod_w01_sp: calc(100% - #{$mod_w_pd01_sp*2});

// コンテンツ幅セット
%mod_w01 {
	margin:0 auto;
	padding-left:$mod_w_pd01;
	padding-right:$mod_w_pd01;
	max-width:$mod_w01;
	width:100%;
	min-width:$mod_w01_min;
	box-sizing:border-box;

	@include sc(sp) {
		width:auto;
		min-width:0;
		padding-left:$mod_w_pd01_sp;
		padding-right:$mod_w_pd01_sp;
	}
}


//デバイスフォントのボールド：アンドロイド対策
%fontbold {
	font-weight:bold;
	@include sc(sp) {
		font-weight:normal;
		-webkit-text-stroke:0.4px;
	}
}

// clearfix
%clearfix {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}


//縦中央揃え
%vertmiddle {
	&:before {
		content:'';
		display:inline-block;
		height:100%;
		vertical-align:middle;
	}
	& > * {
		display:inline-block;
		vertical-align:middle;
	}//& > *
}


//右矢印
%rightarr {
	position:relative;
	&:after {
		content:"";
		display:block;
		margin:auto 0;
		border-top:2px solid;
		border-right:2px solid;
		width:6px;
		height:6px;
		position:absolute;
		top:0;
		bottom:0;
		right:16px;
		transform:rotate(45deg) skew(8deg,8deg);
		transition:transform .5s,opacity .5s;
		@include sc(sp) {
		}
	}
	.no-touchevents &:hover:after {
		transform:rotate(45deg) translate(3px,-3px);
	}//.no-touchevents &:hover:after
}//%rightarr


//Backgroundの画像
%ph {
	background-size:cover;
	background-position:center;
	background-repeat:no-repeat;
}


/*-------------------------------------------*/

/*
Poppinsフォント設定
500：Medium（デフォルト）
600：Semibold
※SemiBoldのみitalicあり
*/
@mixin f_poppins($weight:500) {
	font-family: "poppins",sans-serif;
	font-weight:$weight;
}

/*
Caveatフォント設定
400：Regular（デフォルト）
700：Bold
*/
@mixin f_caveat($weight:400) {
	font-family: 'Caveat', cursive;
	font-weight:$weight;
}

/*
FOT-TsukuARdGothic Stdフォント設定
400：Regular（デフォルト）
700：Bold
*/
@mixin f_fot($weight:400) {
	font-family: 'fot-tsukuardgothic-std', sans-serif;
	font-weight:$weight;
}

/*
Yantramanavフォント設定
300：Light
400：Regular
500：Medium
600：Semibold
700：Bold（デフォルト）
900
*/
@mixin f_yantra($weight:700) {
	font-family: 'Yantramanav', sans-serif;
	font-weight:$weight;
}


/*
Ryoゴシックフォント設定
500：Medium（デフォルト）
700：Bold
*/
@mixin f_ryo($weight:500) {
	font-family: "ryo-gothic-plusn";
	font-weight:$weight;
}

/*
デバイスゴシックフォント設定
400：Regular（デフォルト）
*/
@mixin f_go($weight:400) {
	font-family: $default;
	font-weight:$weight;
}



//縦中央揃え（ベースライン非依存/親要素にHeightが必要）
/*
example
@include vaM;
*/
@mixin vaM {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}



//テキスト均等揃え
/*
example
@include taJ
*/
@mixin taJ {
	text-align: justify;
	text-justify: inter-ideograph;
}


//背景ドット柄
/*
example
@include dot_bg(ドット色,透明度,背景サイズに対するドット面積%,)
*/
@mixin dot_bg($color,$alpha,$size) {
	//background: transparent;
	background-image: radial-gradient(rgba($color, $alpha) $size, transparent 0);
	background-size: 11px 11px;
	background-position: -5px -5px;
}


//汎用破線
/*
example
@include dotted(#COLOR, WidthPX,SpaceingPX,HeightPX); 横破線
@include dotted(#e6e1d2, 4,4,2);
*/
@mixin dotted($color:rgba(#000, 1.0), $stripe: 1, $spacing: 4, $height:1){
	background-image: linear-gradient(to left, $color, $color (calc($stripe / ($stripe + $spacing)) * 100%), transparent (calc($stripe / ($stripe + $spacing)) * 100%), transparent 100%);
	background-size: ($stripe+$spacing)*1px ($stripe+$spacing)*1px;
	height: $height*1px;
 border: none;
}


//汎用斜線背景
/*
@include border(color,opacity);
@include border(#cc0000,0.5);
*/
@mixin border($color:#000,$opacity:1.0){
	background-size: 4px 4px;
	background-image: linear-gradient(-45deg, rgba($color,$opacity) 25%, transparent 25%,
transparent 50%, rgba($color,$opacity) 50%, rgba($color,$opacity) 75%,
transparent 75%, transparent);
}

//片羽矢印
.tp_arr02 {
	$bgc:#000;//軸色
	$IBdeg:-45deg;//羽角度
	$AnimTime: 1.5s;//ループタイム
	i {
		display: inline-block;
		position: relative;
		height: 9px;//10px;
		width: 9px;//10px;
		border-left: 1px solid $bgc;
		&:before,&:after {
			content: '';
			position: absolute;
			display: block;
			background-color: $bgc;
		}
		&:before {//羽
			top: 50%;
			right: 0;
			width: 1px;
			height: 13px;//14px;
			margin-top: -10px;
			transform-origin: right bottom;
			transform:scaleX(1) rotate($IBdeg);
		}
		&:after {//軸
			height: 1px;
			width: 400%;
			right: 0;
			bottom: 0;
		}
		/*@include sc(sp){
			width: 37px;
			&:before {
				right: 0rem;
				width: 0.14rem;
				height: 0.7rem;
				margin-top: -0.7rem;
			}
		}*/
	}
	&.bold {
		i {
			border-width: 2px;
			&:before {
				width: 2px;
				margin-top: -10px;
			}
			&:after {
				height: 2px;
			}
		}
	}
	@keyframes mvArrowIC {//羽短辺
		0% {
			height: 9px;//10px;
		}
		25% {
			height: 9px;//10px;
		}
		45% {
			height: 9px;//10px;
		}
		55% {
			height: 0px;
		}
		100% {
			height: 0px;
		}
	}
	@keyframes mvArrowIB {//羽斜辺
		0% {
			transform:rotate($IBdeg) scaleX(1) translate(0px, 0px);
			height: 13px;
		}
		25% {
			transform:rotate($IBdeg) scaleX(1) translate(0px, 0px);
			height: 13px;
		}
		50% {
			transform:rotate($IBdeg) scaleX(0) translate(-21px, -4px);
			height: 0px;
		}
		75% {
			transform:rotate($IBdeg) scaleX(0) translate(-21px, -4px);
			height: 0px;
		}
		90% {
			transform:rotate($IBdeg) scaleX(1) translate(-21px, -4px);
			height: 0px;
		}
	}
	@keyframes mvArrowI {//軸
		0% {
			transform:scaleX(1);
			transform-origin: 100% 50%;
		}
		20% {
			transform:scaleX(0);
			transform-origin: 100% 50%;
		}
		45% {
			transform:scaleX(0);
			transform-origin: 100% 50%;
		}
		55% {
			transform:scaleX(0);
			transform-origin: 0 50%;
		}
		85% {
			transform:scaleX(0);
			transform-origin: 0 50%;
		}
		100% {
			transform:scaleX(1);
			transform-origin: 0 50%;
		}
	}
	.no-touchevents &:hover {
		i {
			opacity: 0.7;
			transition: opacity 0.2s;
			animation: mvArrowIC $AnimTime ease-in-out 0s infinite;
			&:before {//羽
				animation: mvArrowIB $AnimTime ease-in-out 0s infinite;
			}
			&:after {//軸
				animation: mvArrowI $AnimTime ease-in-out 0s infinite;
			}
		}
	}
}

